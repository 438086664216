<template>
  <div class="not-found">
    <div class="msg-box">
      <h1 class="animated fadeInDown">Error</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',

  data () {
    return {}
  },

  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  }
}
</script>
